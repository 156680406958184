<template>
    <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col"></div>
    <form>
        <div class="grid lg:grid-rows-3 md:grid-rows-3 md:grid-cols-1 sm:grid-flow-col gap-4 place-items-center ">
            <div class="text-center">
                <p>Olá, {{$store.state.user.name}}.</p>
                <p>Meu Espaço.</p>
                <br>
                <div class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="relatorioAvaliacao()">Relatório de Avaliação</button>
                </div>
                <br>
                <div v-if="$store.state.user.chefe == 1 || $store.state.user.copad == 1" class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="uploadPDF()">Upload de Arquivos ao Mural</button>
                </div>
                <br>
                <div v-if="$store.state.user.apto == 1" class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="progressaoUploadPDF()">Solicitar Progressão Vertical</button>
                </div>
                <br>
                <div v-if="$store.state.user.apto == 1" class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="avaliacaoVertical()">Arquivos da Avaliação Vertical</button>
                </div>
            </div>
        </div>
    </form>
</template>
    
    <script>
    import axios from 'axios';

    export default {
    
        components: {
        },
        data()
        {
            return { }
        },
        mounted()
        {
            if(this.$store.state.user == "")
                this.$router.push("/login");
        },
        methods: {
            relatorioAvaliacao()
            {
                console.log("Relatório de Avaliação");
                let url = process.env.VUE_APP_HOST_API;
    
                axios.post(url+'/relatorios/avaliacao/servidor',{matricula: this.$store.state.user.matricula},{
                    headers: {
                        Accept: 'application/pdf',
                        Authorization: `Bearer ${localStorage.token}`
                    },
                    responseType: 'blob',
                    data: { matricula: this.$store.state.user.matricula }
                })
                .then((response) => {
                    console.log("Deu bom!");  
                    let blob = new Blob([response.data], {
                                type: 'application/pdf'
                            })
                            var pdf = window.URL.createObjectURL(blob);
                    //alert(pdf);
                    var a = document.createElement("a");
                    a.href = pdf;
                    var filename = "Relatório de Avaliação - " + this.$store.state.user.name;
                    a.download = filename;
                    a.click();
                }).catch(error => (
                    console.log(error)
                )); 
            },
            avaliacaoVertical()
            {
                this.$router.push("/dados/vertical");
            },
            uploadPDF()
            {
                this.$router.push("/upload/mural");
            },
            progressaoUploadPDF()
            {
                this.$router.push("/progressao")
            }
        }
    }
    </script>
    
    <style scoped>
    
    label {
        font-weight: 700;
        color:white;
    }

    
    p, li {
        font-size: x-large;
        color: white;
    }
    
    
    </style>