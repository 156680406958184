import { createStore } from 'vuex'

export default createStore({
  state: {
    user: "",
    token: ""
  },
  getters: {
  },
  mutations: {
    storeUser(state, data){
      console.log("store user: ", data);
      state.user = data;
    },
    storeToken(state, data){
      console.log('store token: ', data);
      state.token = data;
    },
    clearUserData(state){
      console.log('clear user data: ');
      state.token = '';
      state.user = '';
    }
  },
  // Actions performam respostas async e Mutations não!
  actions: {
      storeUser({commit}, data){
        console.log("action store user: ");
        commit('storeUser', data);
      },
      storeToken({commit}, data){
        console.log("action store token: ");
        commit('storeToken', data);
      },
      clearUserData({commit}){
        console.log("action clear user data: ");
        commit('clearUserData');
      }
  },
  modules: {
  
  }
})
