<template>
    <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white"></h2></div>
    </div>
    <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white">Avaliação da COPAD</h2></div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover text-dark">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servidor</th>
                    </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(servidor, index) in servidores" :key="servidor.matricula">
                      <th scope="row" class="text-dark">{{index+1}}</th>
                      <td v-on:click="visualizarCertificados(servidor.matricula)"><a href="#">{{servidor.nome}}</a></td>
                    </tr>
                  </tbody>
              </table>       
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';


export default {

    components: {

    },
        data()
    {
        return {
            servidores: []
        }
    },
    mounted()
    {
        if(this.$store.state.user.copad != 1)
           this.$router.push("/");
           
        this.getServidores();    
    },
    methods: {
        getServidores()
        {   
                     
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/copad/servidores/aptos',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {servidor: this.$store.state.user.matricula}
            })
            .then((response) => {
                this.servidores = response.data.payload;
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
            });
            
        },
        visualizarCertificados(matricula)
        {
            this.$router.push({ path: `/copad/certificados/${matricula}`});
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000;
    font-size: x-large;
}

.red{
    color: red;
}

th {
    color: white;
}

table, thead{
    border: 1px solid white;
}

</style>
