
export function authCheck()
{
    if(localStorage.token === "" || typeof localStorage.token === 'undefined')
    {
        return false;
    }else
    {
        return true;
    }
}

export function ucwords(word)
{
    return (word + '').toLowerCase().replace(/\b[a-z]/g, function(letter) {
        return letter.toUpperCase();
    });
}