<template>

    <div class="grid xl:grid-cols-10 lg::grid-cols-6 md:grid-cols-6 sm:grid-flow-col gap-4 place-items-center text-white overflow-auto"  :class="overlay">
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify">
            <p>Olá, {{$store.state.user.name}}.</p> 
            <p>Painel do Servidor.</p>
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="avaliacaoAnual()" data-cy="avaliacaoAnual">Avaliação Anual</button>                
        </div>
        <div v-if="apto == 1 || apto == 5" class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="solicitarAvaliacaoVertical()">Solicitar Avaliação Vertical</button>
        </div>
    </div>
</template>
    
<script> 
    import axios from 'axios';

    export default {
    
        components: {
        },
        data()
        {
            return { 
                apto: 0,
                url: ''
            }
        },
        mounted()
        {
            if(this.$store.state.user == "")
                this.$router.push("/login");

            console.log(this.$store.state.user)

            this.url = process.env.VUE_APP_HOST_API;
            axios.post(this.url+'/get-avaliacao',{servidor: this.$store.state.user.matricula},{
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    data: {
                        servidor: this.$store.state.user.matricula
                    }
                })
                .then((response) => {   
                    //console.log(response.data.payload);
                    let resposta = response.data.payload.avaliacao;
                    this.apto = resposta.apto;
                }).catch(error => (
                    console.log(error)
                ));

        },
        methods: {
            solicitarAvaliacaoVertical()
            {
                this.$router.push("/avaliacao-vertical");
            },
            avaliacaoAnual()
            {
                this.$router.push("/avaliacao-geral");
            }
        }
    }
</script>

<style scoped>
    
</style>