<template>
    <div class="grid md:grid-rows-2 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white">HABILITAR SERVIDORES PARA PROGRESSÃO VERTICAL</h2></div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servidor</th>
                    </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(servidor, index) in servidores" :key="servidor.matricula">
                      <th scope="row">{{index+1}}</th>
                      <td v-on:click="alterarSenha(servidor.matricula)"><a href="#">{{servidor.nome}}</a></td>
                    </tr>
                  </tbody>
              </table>       
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';


export default {

    components: {

    },
    data()
    {
        return {
            servidores: [],
            nova_senha: ""
        }
    },
    mounted()
    {
        if(this.$store.state.user.admin != 1)
           this.$router.push("/");
           
        this.getServidores();    
    },
    methods: {
        getServidores()
        {                
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/copad/aptidao/servidores',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {servidor: this.$store.state.user.matricula}
            })
            .then((response) => {
                this.servidores = response.data.payload;
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
            });
            
        },
        async alterarSenha(matricula)
        {
            let senhaCorreta = false;
            let url = process.env.VUE_APP_HOST_API;

            do {

                let { value: senhas} = await this.$swal.fire({
                    title: 'Alterar Senha',
                    html: `
                        <input id="swal-input1" class="swal2-input" placeholder="Senha">
                        <input id="swal-input2" class="swal2-input" placeholder="Confirmar Senha">
                    `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return [
                        document.getElementById("swal-input1").value,
                        document.getElementById("swal-input2").value
                        ];
                    }
                })
                
                if(senhas[0] === senhas[1] && senhas[0] != "" && senhas[1] != ""  && senhas[0].length > 2 && senhas[1].length > 2 )
                {
                    senhaCorreta = true;
                    this.nova_senha = senhas[0];
                }
                else
                {
                    senhaCorreta = false;
                    alert("Senhas Diferentes");
                }

            } while (senhaCorreta == false);

            axios.post(url+'/registrar/senha',{
                    matricula: matricula,
                    senha: this.nova_senha },{
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.$store.state.token}`
                    }
                })
                .then((response) => {
                    this.resposta = response.data.payload;
                    console.log(this.resposta);
                    this.$swal({
                        title: 'Senha Modificada.',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                    });   
                }).catch(error => (
                    this.$swal({
                        html: "<pre>" + error + ".<br><br>Entre em contato com a equipe de TI.</pre>",
                        confirmButtonColor: 'red'
                    })
                ));

            
        }
    }
}
</script>


<style>
div:where(.swal2-container) input:where(.swal2-input)::placeholder,
div:where(.swal2-container) input:where(.swal2-file)::placeholder,
div:where(.swal2-container) textarea:where(.swal2-textarea)::placeholder {
  color:#86889b !important;
}

</style>

<style scoped>


a {
    text-decoration: none;
    color: #000;
    font-size: x-large;
}

th {
    color: black;
}

table, thead{
    border: 1px solid white;
}


</style>
