<template>
  <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
    <div><h2 class="text-center text-white">Links Rápidos</h2></div>
  </div>
  <div class="grid lg:grid-rows-2 md:grid-rows-2 md:grid-cols-3 sm:grid-flow-col gap-4 place-items-center ">
      <div >
          <a href="/noticias">
              <div class="card bg-dark">
                  <img :src="require('@/assets/images/noticias.png')" class="card-img-top not-ready">
                  <div class="centered">Em Breve</div>
                  <div class="card-body">
                  <p class="card-text">Notícias</p>
                  </div>
              </div>
          </a>
      </div>
      <div >
            <router-link to="/mural">
              <div class="card bg-dark">
                  <img :src="require('@/assets/images/mural.png')" class="card-img-top">
                  <div class="card-body">
                  <p class="card-text">Mural Eletrônico</p>
                  </div>
              </div>
            </router-link>
      </div>
      <div>
         <a href="/chamados">
              <div class="card bg-dark">
                  <img :src="require('@/assets/images/chamados.png')" class="card-img-top not-ready">
                  <div class="centered">Em Breve</div>
                  <div class="card-body">
                  <p class="card-text">Chamados</p>
                  </div>
              </div>
          </a>
      </div>
      <div >
          <a href="https://correio.interlegis.leg.br/" target="_blank">
              <div class="card bg-dark">
                  <img :src="require('@/assets/images/email.png')" class="card-img-top">
                  <div class="card-body">
                  <p class="card-text">Email Legislativo</p>
                  </div>
              </div>
          </a>
      </div>
      <div >
          <router-link to="/copad" data-cy="copad">
            <div class="card bg-dark">
                  <img :src="require('@/assets/images/avaliacao.png')" class="card-img-top">
                  <div class="card-body">
                  <p class="card-text">COPAD</p>
                  </div>
              </div>
          </router-link>
      </div>
      <div>
            <router-link to="/meu-espaco">
              <div class="card bg-dark">
                  <img :src="require('@/assets/images/workspace.png')" class="card-img-top">
                  <div class="card-body">
                  <p class="card-text">Meu Espaço</p>
                  </div>
              </div>
            </router-link>
      </div>
  </div>
</template>

<script>

export default {

  components: {

  },
  data()
  {
      return { }
  },
  mounted ()
  {
    if(this.$store.state.user == "")
        this.$router.push("/login");
  },
  methods: {
      validarCampos() {}
  }
}
</script>

<style scoped>

  .card {
      width: 250px;
      border: 1px solid white;
      border-radius: 2px solid white;
      margin: .5rem;
  }

  img:hover, .not-ready:hover {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
  }

  .not-ready:hover {
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
  }

  .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
  }

  .not-ready:hover + .centered{
      display: block;
      font-size: x-large;
      color: black;
      transform: translate(-55%, -55%) rotate3d(0, 0, 1, -45deg);
  }

  .card-text{
    color: white;
    text-align: center;
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
  }

@media only screen and (max-width: 916px)
{
  /* For mobile phones: */

}


@media only screen and (max-width: 768px)
{
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
</style>