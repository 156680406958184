<template>
  <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
    <div><h2 class="text-center text-white"> </h2></div>
  </div>
<div class="container h-100" >
    <div class="row align-items-center justify-content-center h-100">
        <div class="col-10 text-white text-center">
            <p>Olá, {{$store.state.user.name}}</p>
            <p>Painel do Servidor</p>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="habilitarServidoresVertical()">Habilitar Servidores para Progressão Vertical</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="avaliarServidoresVertical()">Avaliar Servidores que Solicitaram Avaliação Vertical</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="relatorioAvaliadosDepartamento()">Relatório de Servidores de Avaliados pelo Departamento</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="relatorioAvaliadosVerticalCOPAD()">Relatório de Servidores de Avaliação Vertical pela COPAD (FINAL)</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="relatorioAvaliadosDepartamento()">Relatório de Servidores Avaliados Por Departamento</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="relatorioAptosVertical()">Relatório de Aptos para Avaliação Vertical</button>
            </div>
            <br>
            <div class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="relatorioAptosHorizontal()">Relatório de Aptos para Avaliação Horizontal</button>
            </div>
            <br>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import 'moment/locale/pt-br';

export default {

    components: {
    },
    data()
    {
        return {
            apto: false,
            chefe: false,
            servidor: []
        }
    },
    mounted()
    {
        let url = process.env.VUE_APP_HOST_API;
        axios.post(url+'/servidor',{servidor: this.$store.state.user.matricula},{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.$store.state.token}`
            },
            data: {
                servidor: this.$store.state.user.matricula
            }
        })
        .then((response) => {
            this.servidor = response.data.payload;
            console.log(this.servidor);
            this.apto = this.servidor.APTO;
        }).catch(error => (
            console.log(error)
        ));
    },
    methods:
    {
        habilitarServidoresVertical()
        {
            this.$router.push("/copad/habilitar-vertical");
        },
        avaliarServidoresVertical()
        {
            this.$router.push("/copad/membro/avaliar-vertical");
        },
        relatorioAvaliadosVerticalCOPAD()
        {
            console.log("Relatório de Avaliação COPAD");
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/relatorios/copad/avaliados-vertical-copad',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                var pdf = window.URL.createObjectURL(blob)
                window.open(pdf);
            }).catch(error => (
                console.log(error)
            )); 
        },
        relatorioAvaliadosDepartamento()
        {
            console.log("Relatório dos Avaliados pelo Departamento");
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/relatorios/copad/avaliados-departamento',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                var pdf = window.URL.createObjectURL(blob)
                var a = document.createElement("a");
                    a.href = pdf;
                    var filename = "Relatório de Servidores Avaliados pelo Departamento";
                    a.download = filename;
                    a.click();
            }).catch(error => (
                console.log(error)
            )); 
        },
        relatorioAvaliadosHorizontalCOPAD()
        {
            console.log("Relatório de Avaliação");
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/relatorios/copad/avaliados-horizontal-copad',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                var pdf = window.URL.createObjectURL(blob)
                window.open(pdf);
            }).catch(error => (
                console.log(error)
            )); 
        },
        relatorioAptosVertical()
        {
            console.log("Relatório de Avaliação");
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/relatorios/copad/aptos-vertical',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                        var pdf = window.URL.createObjectURL(blob);
                //alert(pdf);
                var a = document.createElement("a");
                a.href = pdf;
                var filename = "Relatório de Servidores Aptos à Progressão Vertical";
                a.download = filename;
                a.click();
            }).catch(error => (
                console.log(error)
            )); 
        },
        relatorioAptosHorizontal()
        {
            console.log("Relatório de Avaliação");
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/relatorios/copad/aptos-horizontal',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                var pdf = window.URL.createObjectURL(blob)
                var a = document.createElement("a");
                a.href = pdf;
                var filename = "Relatório de Servidores Aptos à Progressão Horizontal";
                a.download = filename;
                a.click();
            }).catch(error => (
                console.log(error)
            ));
        },
        adicionarPDF()
        {
            this.$router.push("/upload/mural");
        }
    }
}
</script>

<style scoped>
    p {
        font-size: x-large;
    }
</style>