<template>
    <div class="text-center text-white">
        <h1>AVALIAÇÃO DO CHEFE IMEDIATO</h1>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover text-dark">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servidor</th>
                    </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(servidor, index) in servidores" :key="servidor.matricula">
                      <th scope="row" class="text-dark">{{index+1}}</th>
                      <td v-on:click="avaliarServidor(servidor.matricula)"><a href="#">{{servidor.nome}}</a></td>
                    </tr>
                  </tbody>
              </table>       
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';


export default {

    components: {

    },
        data()
    {
        return {
            servidores: []
        }
    },
    mounted()
    {
        if(this.$store.state.user.chefe != 1)
            this.$router.push("/");

           
        this.getServidores();    
    },
    methods: {
        getServidores()
        {   
                     
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/avaliacao/chefe/servidores',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.token}`
                },
                data: {servidor: this.$store.state.user.matricula}
            })
            .then((response) => {
                this.servidores = response.data.payload;
                //console.log(this.servidores);
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
                console.log(error);
            });
            
        },
        avaliarServidor(matricula)
        {
            this.$router.push({ path: `/avaliacao/chefe/${matricula}`});
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000;
    font-size: x-large;
}

.red{
    color: red;
}

th {
    color: white;
}

table, thead{
    border: 1px solid white;
}

</style>
