<template>
    <div class="text-center text-white">
        <h1>Avaliação Geral</h1>
        <h1>Formulário de Identificação</h1>
    </div>
    <br>
    <div class="container text-center text-white">
        <div class="row">
            <div class="col-md-6 col-sm-12"><p><b>1. Identificação do Servidor:</b></p></div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12"><p>Matrícula: <span class="display">{{$store.state.user.matricula}}</span></p></div>
            <div class="col-md-6 col-sm-12"><p>Nome: <span class="display">{{$store.state.user.name}}</span></p></div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12"><p>Cargo: <span class="display">{{ avaliacao.cargo_desc }}</span></p></div>
            <div class="col-md-6 col-sm-12"><p>Lotação: <span class="display">{{ avaliacao.lotacao }}</span></p></div>
        </div>
         <div class="row">
            <div class="col-md-6 col-sm-12"><p><b>2. Identificação do Chefe Imediato:</b></p></div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-12"><p>Nome: <span class="display">{{ chefe.nome }}</span></p></div>
            <div class="col-md-6 col-sm-12"><p>Cargo: <span class="display">{{ chefe.cargo }}</span></p></div>
        </div>
        <div class="row" :class="instrucoes">
            <div class="col-md-12 col-sm-12"><p><b>4. Instruções</b></p>
                <ol class=" text-start">
                    <li>Esta avaliação é composta dos fatores de pontualidade, assiduidade, produtividade, eficiência, 
                        adequação às normas, iniciativa, liderança, planejamento, zelo e relações interpessoais.
                    </li>
                    <li>Cada quesito deverá ser analisado, levando em consideração o seu desempenho, e marcado apenas uma alternativa,
                        a que mais se aproxima da sua autoavaliação.
                    </li>
                    <li>Cada alternativa avaliada será distribuída a seguinte pontuação: 0,25; 0,50; 0,75; 1,00.</li>
                    <li>Após marcar o formulário, aguardar emissão de parecer do chefe imediato.</li>
                </ol>
                <div class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="iniciarAvaliacao()" data-cy="iniciarAvaliacao">Avançar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {

    components: {

    },
    data() {
        return {
            avaliacao: [],
            chefe: [],
            apto: 0
        }
    },
    mounted () {

        this.checarAvaliacao()

        let url = process.env.VUE_APP_HOST_API;

        axios.post(url+'/servidor/',{servidor: this.$store.state.user.matricula},{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.$store.state.token}`
            },
            data: {
                servidor: this.$store.state.user.matricula
            }
        })
        .then((response) => {
            this.avaliacao = response.data.payload;
            console.log(this.avaliacao);

                axios.post(url+'/servidor/chefe',{servidor: this.avaliacao.matricula_resp},{
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    })
                    .then((response) => {
                        this.chefe = response.data.payload;
                        console.log(this.chefe);
                    }).catch(error => (
                        console.log(error)
                    ));
        }).catch(error => (
            console.log(error)
        ));


    },
    methods: {
        iniciarAvaliacao()
        {
            if(true)
            {
                this.$router.push("/avaliacao/servidor")
            }
        },
        checarAvaliacao()
        {
            this.url = process.env.VUE_APP_HOST_API;
            axios.post(this.url+'/checar-avaliacao',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {
                    servidor: this.$store.state.user.matricula
                }
            })
            .then((response) => {
                let resposta = response.data.payload;

                this.apto = resposta.avaliacao.apto;
                if(resposta.avaliacao.apto != 1)
                {
                    const message = "Você já fez a avalição. Agora você será redirecionado.";
                    this.$swal({
                        html: message
                    });

                    this.$router.push("/");
                }
            }).catch(error => (
                console.log(error)
            ));
        }
    }
}
</script>

<style scoped>
p{
    text-align: left;
}

.display {
    font-size: larger;
    text-shadow: 2px 2px 4px #000000;
}

@media only screen and (max-width: 768px)
{
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  .col-sm-12 {
    width: 135% !important;
    margin: 0rem 0rem 0rem -1.5rem;
  }

  button {
    align-items: center;
  }

  p, li {
    font-size: medium;
  }

 .instrucoes::before, .instrucoes:after {
    box-sizing: content-box;
 }

}
</style>