<template>
  <div class="flex items-center">
    <a :href=url class="flex items-center centro"><img :src="require('@/assets/brasao.png')" class="h-30"></a>
  </div>

    <form>
      <div class="grid lg:grid-rows-1 md:grid-rows-1 md:grid-cols-1 sm:grid-flow-col gap-4 place-items-center">
          <div class="white-box">
              <div class="mb-3">
                  <label class="form-label">Matrícula</label>
                  <input type="text" class="form-control" v-model="matricula" data-cy="matricula">
              </div>
              <div class="mb-3">
                  <label class="form-label">Senha</label>
                  <input type="password" class="form-control" v-model="senha" data-cy="senha">
              </div>
              <div class="content-center botao" >
                <button type="submit" class="btn text-light bg-dark" @click.prevent="login" data-cy="submit">Entrar</button>
              </div>
          </div>
      </div>
    </form>
</template>

<script>

import axios from 'axios';



export default {
  components: {

  },
  data() {
      return {
          matricula : "",
          senha: "",
          resposta: [],
          url : process.env.VUE_APP_HOST
      }
  },
  mounted () {
    
  },
  methods: {
      login() {
          let url = process.env.VUE_APP_HOST_API;
          
          axios.post(url+'/login',{
              matricula: this.matricula,
              senha: this.senha
          })
          .then((response) => {
              const usuario = response.data.payload.user;
              const token = response.data.payload.token;

              this.$store.dispatch('storeUser', usuario);
              this.$store.dispatch('storeToken', token);


              this.$swal({
                  title: 'Acesso Permitido.',
                  confirmButtonText: 'Ok'
              }).then((result) => {
                this.$router.push("/");
              });
              
          }).catch(error => (
                  this.$swal({
                      html: "<pre>" + error + ".<br><br>Usuário ou senha não conferem.</pre>",
                      confirmButtonColor: 'red'
                  })
          ));
          
      }
  }
}
</script>

<style scoped>

  .white-box{
    padding: 3rem;
  }

  label {
    font-weight: 700;
    color:white;
  }

  .botao {
    display: grid;
  }

  .centro {
    margin: 0px auto;
  }

  #brasao {
    visibility: hidden;
  }
</style>
