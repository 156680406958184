<template>
    <div class="container text-start">
        <!-- PONTUALIDADE -->
        <div class="row">
            <div class="col">
                <h5>1. PONTUALIDADE</h5>   {{questoes.q1}} 
            </div>
        </div>
        <!-- ASSIDUIDADE -->
        <div class="row">
            <div class="col">
                <h5>2. ASSIDUIDADE</h5> {{questoes.q2}}
            </div>
        </div>
        <!-- PRODUTIVIDADE -->
        <div class="row">
            <div class="col">
                <h5>3. PRODUTIVIDADE</h5> {{questoes.q3}}
            </div>
        </div>
        <!-- EFICIÊNCIA -->
        <div class="row">
            <div class="col">
                <h5>4. EFICIÊNCIA</h5> {{questoes.q4}}
            </div>
        </div>
        <!-- ADEQUAÇÃO AS NORMAS DA CASA -->
        <div class="row">
            <div class="col">
                <h5>5. ADEQUAÇÃO AS NORMAS DA CASA</h5> {{questoes.q5}}
            </div>
        </div>
        <!-- INICIATIVA -->
        <div class="row">
            <div class="col">
                <h5>6. INICIATIVA</h5> {{questoes.q6}}
            </div>
        </div>
        <!-- LIDERANÇA -->
        <div class="row">
            <div class="col">
                <h5>7. LIDERANÇA</h5> {{questoes.q7}}
            </div>
        </div>
        <!-- PLANEJAMENTO -->
        <div class="row">
            <div class="col">
                <h5>8. PLANEJAMENTO</h5> {{questoes.q8}}
            </div>
        </div>
        <!-- ZELO -->
        <div class="row">
            <div class="col">
                <h5>9. ZELO</h5> {{questoes.q9}}
            </div>
        </div>
        <!-- RELAÇÕES INTERPESSOAIS -->
        <div class="row">
            <div class="col">
                <h5>10. RELAÇÕES INTERPESSOAIS</h5> {{questoes.q10}}
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
    data(){
        return {
            questoes: [],
            token: ""
        }
    },
    computed: {
    },
    mounted()
    {
        this.getRespostas();
    },
    methods: {
        validarForm()
        {
            this.erros = [];
        },  
        getRespostas()
        {            
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/avaliacao/servidor/respostas',{servidor: this.$route.params.servidor},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {servidor: this.$route.params.servidor}
            })
            .then((response) => {
                this.questoes = response.data.payload;
                console.log(this.questoes);
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
            });
        },
        validarCampos()
        {

        }
    }
}
</script>