<template>
    
</template>

<script>


export default {
    components: {
    },
    mounted()
    {
        this.$store.dispatch("clearUserData");
        this.$router.push("/login");
    }
}
</script>