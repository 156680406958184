<template>
    <div class="grid lg:grid-cols-6 md:grid-cols-6 sm:grid-flow-col gap-4 place-items-center text-white overflow-auto">
        <div class="col-start-2 col-span-4 text-justify">
            <br>
            <p>Esse sistema tem como objetivo regulamentar os procedimentos de avaliação de desempenho de servidor público estável para fins de progressão funcional.
            <br>O sistema de avaliação está disponível a partir de solicitação da COPAD,e acordo com a Resolução nº 225, de 10 de Agosto de 2023. </p>
            <p>O sistema contribui diretamente para:</p>

            <ul>
                <li>A melhoria da qualidade e eficiência do serviço público;</li>
                <li>Avalia aptidão e capacidade para exercício do cargo;</li>
                <li>Promove a valorização do servidor, por meio de progressão funcional;</li>
            </ul>
            <br><br>
            <p>Essa avaliação verifica os seguintes critérios:</p>
            <ul>
                <li>PONTUALIDADE</li>
                <li>ASSIDUIDADE</li>
                <li>PRODUTIVIDADE</li>
                <li>EFICIÊNCIA</li>
                <li>ADEQUAÇÃO AS NORMAS DA CASA</li>
                <li>INICIATIVA</li>
                <li>LIDERANÇA</li>
                <li>PLANEJAMENTO</li>
                <li>ZELO</li>
                <li>RELAÇÕES INTERPESSOAIS</li>
            </ul>

            <p>Todo processo é 100% online, seguro, discreto, sigiloso.</p>
            
            <br>
            <div v-if="apto > 0" class="d-grid gap-2">
                <button class="btn btn-light" type="button" v-on:click="avancar()" data-cy="avancar">Avançar</button>
            </div>
        </div>
    </div>

</template>
    
<script>
    import moment from "moment";
    import 'moment/locale/pt-br';
    import axios from 'axios';

    export default {
    
        components: {
        },
        data()
        {
            return {
                apto: 0,
                url: ''
             }
        },
        mounted()
        {
            if(this.$store.state.user == "")
                this.$router.push("/login");
                
            this.checarAvaliacao();
        },
        methods: 
        {
            avancar()
            {
                this.$router.push("/avaliacao/home");
            },
            dataAvaliacao()
            {
                var date2 = moment('28/08/2023 00:00:00',"DD-MM-YYYY hh:mm:ss");
                var date2 = moment('18/08/2023 00:00:00',"DD-MM-YYYY hh:mm:ss");
                var date1 = moment();
    
                if(date1.isAfter(date2))
                    this.apto = 1;
            },
            checarAvaliacao()
            {
                this.url = process.env.VUE_APP_HOST_API;
                axios.post(this.url+'/checar-avaliacao',{servidor: this.$store.state.user.matricula},{
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    data: {
                        servidor: this.$store.state.user.matricula
                    }
                })
                .then((response) => {
                    let resposta = response.data.payload.avaliacao;
                    this.apto = resposta.apto;

                }).catch(error => (
                    console.log(error)
                ));
            }
        }
    }
</script>
    
<style scoped>


p, li {
    font-size: x-large;
}
    
@media only screen and (max-width: 768px)
{

  p, li {
    font-size: medium;
    text-align: justify;
    
  }

}

</style>