import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home'
import Login from '../views/Home/Login'
import Copad from '../views/Copad/Copad'
import MenuServidor from '../views/Copad/Servidor/MenuServidor'
import AvaliacaoGeral from '../views/Copad/Servidor/AvaliacaoGeral'
import AvaliacaoVertical from '../views/Copad/Servidor/AvaliacaoVertical'
import AvaliacaoServidor from '../views/Copad/Servidor/Avaliacao/AvaliacaoServidor'
import MenuChefe from '../views/Copad/Chefe/MenuChefe'
import Mural from '../views/Mural/Mural'
import AvaliacaoChefeImediato from '../views/Copad/Chefe/AvaliacaoChefeImediato'
import AlterarSenha from '../views/Admin/AlterarSenha'
import Sair from '../views/Home/Sair'
import AvaliacaoHome from '../views/Copad/Servidor/Avaliacao/AvaliacaoHome'
import Upload from '../views/Copad/Servidor/Progressao/Upload'
import MenuMembro from '../views/Copad/Membro/MenuMembro'
import MeuEspaco from '../views/Home/MeuEspaco'
import DadosVertical from '../views/Espaco/DadosVertical'
import AvaliacaoChefePorServidor from '../views/Copad/Chefe/AvaliacaoChefePorServidor'
import HabilitarServidoresVertical from '../views/Copad/Membro/HabilitarServidoresVertical'
//import ProgressaoVertical from '../views/Copad/Membro/ProgressaoVertical'
import VisualizarCertificados from '../views/Copad/Membro/VisualizarCertificados'
import AvaliacaoCopad from '@/views/Copad/Membro/AvaliacaoCopad'
import MenuAdmin from '@/views/Admin/MenuAdmin.vue'
import AlterarMembroCopad from '@/views/Admin/AlterarMembroCopad.vue'
import AlterarChefe from '@/views/Admin/AlterarChefe.vue'
import CriarAdmin from '@/views/Admin/CriarAdmin.vue'
import CriarUsuario from '@/views/Admin/CriarUsuario.vue'
import CriarChefe from '@/views/Admin/CriarChefe.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/copad',
    name: 'copad',
    component: Copad
  },
  {
    path: '/chamados',
    name: 'chamados',
    component: Mural
  },
  {
    path: '/meu-espaco',
    name: 'meu-espaco',
    component: MeuEspaco
  },
  {
    path: '/admin/alterar/senha',
    name: 'admin/alterar/senha',
    component: AlterarSenha
  },
  {
    path: '/sair',
    name: 'sair',
    component: Sair
  },


  //Páginas Meu Espaço

  {
    path: '/dados/vertical',
    name: 'dados-vertical',
    component: DadosVertical
  },
  {
    path: '/upload/mural',
    name: 'mural',
    component: Mural
  },

  //Páginas COPAD - Membro da COPAD
  
  {
    path: '/copad/membro',
    name: 'membro',
    component: MenuMembro
  },
  {
    path: '/copad/habilitar-vertical',
    name: 'habilitar-vertical',
    component: HabilitarServidoresVertical
  },


  //Páginas COPAD -  Servidor
  {
    path: '/copad/servidor',
    name: 'servidor',
    component: MenuServidor
  },

  {
    path: '/avaliacao-geral',
    name: 'avaliacao-geral',
    component: AvaliacaoGeral
  },
  {
      path: '/avaliacao-vertical',
      name: 'avaliacao-vertical',
      component: AvaliacaoVertical
  },
  {
    path: '/avaliacao/home',
    name: '/avaliacao/home',
    component: AvaliacaoHome
  },

  {
    path: '/progressao/upload',
    name: '/progressao/upload',
    component: Upload
  },


  {
    path: '/avaliacao/servidor',
    name: 'avaliacao-servidor',
    component: AvaliacaoServidor
  },
  {
    path: '/avaliacao/chefe',
    name: 'avaliacao-chefe',
    component: AvaliacaoChefeImediato
  },

  //Páginas COPAD - Chefe

  {
    path: '/copad/chefe',
    name: 'chefe',
    component: MenuChefe
  },
  {
    path: '/avaliacao/chefe',
    name: 'avaliacao-chefe',
    component: AvaliacaoChefeImediato
  },
  {
      path: '/avaliacao/chefe/:servidor',
      name: 'avaliacao-chefe-servidor',
      component: AvaliacaoChefePorServidor,
      props: true
  }
  ,
  {
    path: '/copad/membro/avaliar-vertical',
    name: 'copad-membro-avaliar-vertical',
    component: AvaliacaoCopad
  },
  {
    path: '/copad/certificados/:servidor',
    name: 'copad-certificados-servidor',
    component: VisualizarCertificados,
    props: true
  },


  //Páginas Administrador
  {
    path: '/admin',
    name: 'admin',
    component: MenuAdmin
  },
  {
    path: '/admin/alterar/membro/copad',
    name: 'admin-alterar-membro-copad',
    component: AlterarMembroCopad
  },
  {
    path: '/admin/alterar/chefe',
    name: 'admin-alterar-chefe',
    component: AlterarChefe
  },
  {
    path: '/admin/alterar/admin',
    name: 'admin-alterar-admin',
    component: CriarAdmin
  },
  {
    path: '/admin/criar/usuario',
    name: 'admin-criar-usuario',
    component: CriarUsuario
  },
  {
    path: '/admin/criar/chefe',
    name: 'admin-criar-chefe',
    component: CriarChefe
  }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router


