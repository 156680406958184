<template>
  <div class="grid md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
    <div><h2 class="text-center text-white">MURAL ELETRÔNICO</h2></div>
  </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Arquivo</th>
                        <th scope="col">Data</th>
                        <th scope="col">Autor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(file,index) in files" :key="file.nome">
                        <th scope="row">{{index+1}}</th>
                        <td v-on:click="openFile(file, file.nome)">
                            <a href="#"><span class="red"><i class="bi bi-file-pdf"></i></span>{{file}}</a>
                        </td>
                        <td></td>
                        <td></td>
                        </tr>
                    </tbody>
                </table>   
            </div>
        </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */ 
    import { authCheck } from '@/utils';
    import axios from 'axios'


    export default {
  
      components: {
      },
        data() {
        return {
            user : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : [],
            servidor : [],
            files: [],
            url: "",
            fileName: ""
        }
      },
      mounted()
        {
            if(!authCheck())
              this.$router.push("/login");


            this.url = process.env.VUE_APP_HOST_API;

            axios.get(this.url+'/mural')
                .then((response) => {
                this.files = response.data.payload;
                console.log(this.files);
            }).catch(error => (
                console.log(error)
            ));
            /*
            axios.post(this.url+'/mural',{servidor: this.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.token}`
                },
                data: {
                    servidor: this.user.matricula
                }
            })
            .then((response) => {
                this.files = response.data.payload;
                console.log(this.files);
            }).catch(error => (
                console.log(error)
            ));
            */
        },
        methods:
        {
            openFile(path)
            {
                this.fileName = path;
                axios.post(this.url+'/mural/pdf',{ file: path },{
                    responseType: 'blob'
                })
                .then((response) => {
                    console.log("Then payload: ");
                    
                    console.log(response.data);
                    let blob = new Blob([response.data], {
                                type: 'application/pdf'
                            })
                    var pdf = window.URL.createObjectURL(blob);
                    //alert(pdf);
                    var a = document.createElement("a");
                    a.href = pdf;
                    console.log(response)
                    //alert(response.headers)
                    var filename = "";
                    if(response.headers["content-disposition"] === undefined)
                    {
                        filename = filename = response.config["data"];
                        filename = filename.replace('"','')
                        filename = filename.replace('{','')
                        filename = filename.replace('}','')
                        filename = filename.split(':')
                        filename = filename[1]
                        console.log(filename)
                    }else
                    {
                        filename = response.headers["content-disposition"];
                        filename = filename.split(';')
                        .find(n => n.includes('filename='))
                        .replace('filename=', '')
                        .trim();
                        console.log(filename)
                        filename = filename.substring(1,filename.length-1);
                        console.log(filename);
                    }


                    a.download = filename;
                    a.click();
                }).catch(error => (
                    console.log(error)
                ));
            }
        }
}
  </script>
  
  <style scoped>
  a {
      text-decoration: none;
      color: black;
      font-size: x-large;
  }
  
  .red{
      color: red;
  }
  
  th {
      color: white;
  }
  
  table, thead{
      border: 1px solid white;
  }
  
  </style>