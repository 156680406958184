<template>
    <div class="text-center text-white">
        <h1>Arquivos Enviados à COPAD</h1>
        <h2>Matrícula: {{user.matricula}}</h2>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Arquivo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(file,index) in files" :key="file.nome">
                        <th scope="row">{{index+1}}</th>
                        <td v-on:click="openFile(file)"><a href="#" class="text-dark"><span class="red"><filePDF class="large" />&nbsp;</span>{{file}}</a></td>
                        </tr>
                    </tbody>
                </table>   
            </div>
        </div>
    </div>
</template>

  
<script>
import axios from 'axios';
import filePDF from '../Icons/filePDF.vue';

export default {

    components: {
        filePDF
    },
    data() {
        return {
            servidor : [],
            files: [],
            url: "",
            parecer: "",
            justificativa: ""
        }
    },
    mounted()
    {
        if(this.$store.state.user == "")
            this.$router.push("/login");


        this.servidor = this.$route.params.servidor;

        this.url = process.env.VUE_APP_HOST_API;
        axios.post(this.url+'/dados/vertical',{servidor: this.$store.state.user.matricula},{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.$store.state.token}`
            },
            data: {
                servidor: this.$store.state.user.matricula
            }
        })
        .then((response) => {
            this.files = response.data.payload;
            console.log(this.files);
        }).catch(error => (
            console.log(error)
        ));
    },
    methods: 
    {
        openFile(path)
        {
            this.url = process.env.VUE_APP_HOST_API;
            console.log(path);
            axios.post(this.url+'/show/pdf',{servidor: this.$store.state.user.matricula, file: path},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: {
                    servidor: this.$store.state.user.matricula,
                    file: path
                }
            })
            .then((response) => {
                console.log("Deu bom!");  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                var pdf = window.URL.createObjectURL(blob)
                window.open(pdf);
            }).catch(error => (
                console.log(error)
            ));
        },
        validarForm()
        {
            this.erros = [];
            if(this.parecer === "") this.erros.push('<b>Parecer</b>');
            if(this.justificativa === "" && this.parecer === 'inapto') this.erros.push('<b>Você precisa preencher uma justificatia!</b>');
            if(this.erros.length === 0)
                return true;
            else
                return false;
        }
    }   
}
  </script>
  
  <style scoped>
  a {
      text-decoration: none;
      color: #fff;
      font-size: x-large;
  }

  .large {
    transform: scale(1.5);
  }
  
  .red{
      color: red;
      font-size: x-large;
  }
  
  th {
      color: white;
  }
  
  table, thead{
      border: 1px solid white;
  }
  
  td {
      text-align: left;
  }
  
  </style>