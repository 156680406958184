<template class=" overflow-scroll">
    <div class="container text-start text-white">
        <!-- PONTUALIDADE -->
        <div class="row">
            <div class="col">
                <h4>1. PONTUALIDADE</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="pontualidade" value="a" data-cy="pontualidade-a">
            <label class="form-check-label" for="pontualidade">
                É pontual, raramente entra ou sai fora do horário previsto, e quando isso ocorre comunica a chefia.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="pontualidade" value="b" data-cy="pontualidade-b">
            <label class="form-check-label" for="pontualidade">
                Às vezes chega atrasado ou sai antecipadamente, mas na maioria das vezes, está dentro dos limites de tolerância estabelecidos pelo órgão.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="pontualidade"  value="c" data-cy="pontualidade-c">
            <label class="form-check-label" for="pontualidade">
                Às vezes chega atrasado ou sai antecipadamente, ultrapassando os limites de tolerância estabelecidos pelo órgão.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="pontualidade" value="d" data-cy="pontualidade-d">
            <label class="form-check-label" for="pontualidade">
                Frequentemente entra e sai fora do horário previsto e/ou com frequência para resolver problemas pessoais sem autorização da chefia.
            </label>
        </div>
        <!-- ASSIDUIDADE -->
        <div class="row">
            <div class="col">
                <h4>2. ASSIDUIDADE</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="assiduidade" value="a" data-cy="assiduidade-a">
            <label class="form-check-label" for="assiduidade">
                Nunca faltou ou quando falta apresenta atestado ou justificativa acatada pelo seu superior.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="assiduidade" value="b" data-cy="assiduidade-b">
            <label class="form-check-label" for="assiduidade">
                Raramente faltou sem apresentar atestado ou justificativa acatadas pelo seu superior.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="assiduidade"  value="c" data-cy="assiduidade-c">
            <label class="form-check-label" for="assiduidade">
                Elevado número de faltas, embora geralmente as justifique.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="assiduidade" value="d" data-cy="assiduidade-d">
            <label class="form-check-label" for="assiduidade">
                Faltou até 15 (quinze) dias sem apresentar atestados ou justificativas acatadas pelo seu superior.
            </label>
        </div>
        <!-- PRODUTIVIDADE -->
        <div class="row">
            <div class="col">
                <h4>3. PRODUTIVIDADE</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="produtividade" value="a" data-cy="produtividade-a">
            <label class="form-check-label" for="produtividade">
                Frequentemente conclui seus trabalhos antes dos prazos estimados, sem prejuízo da qualidade, dispondo-se prontamente a iniciar outras tarefas.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="produtividade" value="b" data-cy="produtividade-b">
            <label class="form-check-label" for="produtividade">
                Normalmente conclui seus trabalhos nos prazos estimados sem prejuízo da qualidade.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="produtividade"  value="c" data-cy="produtividade-c">
            <label class="form-check-label" for="produtividade">
                Acomodado em sua situação funcional. Não exerce nenhuma tarefa com boa produtividade.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="produtividade" value="d" data-cy="produtividade-d">
            <label class="form-check-label" for="produtividade">
                Normalmente excede os prazos estimados para conclusão de seus trabalhos.
            </label>
        </div>
        <!-- EFICIÊNCIA -->
        <div class="row">
            <div class="col">
                <h4>4. EFICIÊNCIA</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="eficiencia" value="a" data-cy="eficiencia-a">
            <label class="form-check-label" for="eficiencia">
                Os trabalhos executados são plenamente adequados à finalidade e destacam-se como referência de qualidade.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="eficiencia" value="b" data-cy="eficiencia-b">
            <label class="form-check-label" for="eficiencia">
                Os trabalhos executados eventualmente necessitam de reparos, a fim de tornarem-se adequados à finalidade.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="eficiencia"  value="c" data-cy="eficiencia-c">
            <label class="form-check-label" for="eficiencia">
                Os trabalhos realizados frequentemente necessitam de reparos, a fim de tornar-se adequados à finalidade, ou executados por outro servidor.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="eficiencia" value="d" data-cy="eficiencia-d">
            <label class="form-check-label" for="eficiencia">
                O volume e o nível de trabalho produzido estão abaixo dos padrões esperados.
            </label>
        </div>
        <!-- ADEQUAÇÃO AS NORMAS DA CASA -->
        <div class="row">
                <div class="col">
                    <h4>5. ADEQUAÇÃO AS NORMAS DA CASA</h4>
                </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="adequacao" value="a" data-cy="adequacao-a">
            <label class="form-check-label" for="adequacao">
                É necessário que adquira maior vivência prática. Ainda não conhece as normas. 
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="adequacao" value="b" data-cy="adequacao-b">
            <label class="form-check-label" for="adequacao">
                Utiliza-se das normas para desenvolver suas atividades.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="adequacao"  value="c" data-cy="adequacao-c">
            <label class="form-check-label" for="adequacao">
                Domina as normas, regras e procedimentos, aplicando-os na prática, sem que seja necessário orientação.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="adequacao" value="d" data-cy="adequacao-d">
            <label class="form-check-label" for="adequacao">
                Necessita constantemente ser lembrado da existência das normas.
            </label>
        </div>
        <!-- INICIATIVA -->
        <div class="row">
            <div class="col">
                <h4>6. INICIATIVA</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="iniciativa" value="a" data-cy="iniciativa-a">
            <label class="form-check-label" for="iniciativa">
                Apresenta disponibilidade para ajudar a equipe, apenas em caso de sobrecarga de trabalho.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="iniciativa" value="b" data-cy="iniciativa-b">
            <label class="form-check-label" for="iniciativa">
                Disposição para transmitir conhecimentos, ideias aos demais colegas.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="iniciativa"  value="c" data-cy="iniciativa-c">
            <label class="form-check-label" for="iniciativa">
                Não tem capacidade de iniciar ações para soluções de problemas imediatos ou futuros.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="iniciativa" value="d" data-cy="iniciativa-d">
            <label class="form-check-label" for="iniciativa">
                Sempre propõe novas formas de executar o trabalho visando simplificar procedimentos  a agilizar a relação das atividades.
            </label>
        </div>
        <!-- LIDERANÇA -->
        <div class="row">
            <div class="col">
                <h4>7. LIDERANÇA</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="lideranca" value="a" data-cy="lideranca-a">
            <label class="form-check-label" for="lideranca">
                Não exerce nenhuma influência na equipe, sua atuação é inexpressiva, na obtenção dos resultados desejados.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="lideranca" value="b" data-cy="lideranca-b">
            <label class="form-check-label" for="lideranca">
                Tem empatia, entende sua equipe, seus valores pessoais, dificuldades e sempre contribui para o crescimento de todos.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="lideranca"  value="c" data-cy="lideranca-c">
            <label class="form-check-label" for="lideranca">
                Mantém respeito, ética, reconhecimento, sabe dar e receber <i>feedback</i> e é motivador junto a sua equipe.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="lideranca" value="d" data-cy="lideranca-d">
            <label class="form-check-label" for="lideranca">
                Possui pouca capacidade persuasiva, não exerce grande influência na equipe.
            </label>
        </div>
        <!-- PLANEJAMENTO -->
        <div class="row">
            <div class="col">
                <h4>8. PLANEJAMENTO</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="planejamento" value="a" data-cy="planejamento-a">
            <label class="form-check-label" for="planejamento">
                Desorganizado em seu planejamento. Não programa e nem utiliza métodos adequados.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="planejamento" value="b" data-cy="planejamento-b">
            <label class="form-check-label" for="planejamento">
                É capaz de estabelecer bons programas de trabalho, possuindo boa metodologia, mas eventualmente necessita de orientação.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="planejamento"  value="c" data-cy="planejamento-c">
            <label class="form-check-label" for="planejamento">
                É capaz de planejar, traçar metas e executar o trabalho no setor.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="planejamento" value="d" data-cy="planejamento-d">
            <label class="form-check-label" for="planejamento">
                Limitado na execução dos planos de ação sob orientação. É capaz de elaborar algum planejamento, mas sua metodologia nem sempre é adequada à execução dos objetivos.
            </label>
        </div>
        <!-- ZELO -->
        <div class="row">
            <div class="col">
                <h4>9. ZELO</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="zelo" value="a" data-cy="zelo-a">
            <label class="form-check-label" for="zelo">
                Apresenta cuidado no trato com o patrimônio e mantém o ambiente de trabalho organizado.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="zelo" value="b" data-cy="zelo-b">
            <label class="form-check-label" for="zelo">
                Realiza as suas atividades considerando os reflexos, sobre as pessoas e o ambiente.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="zelo"  value="c" data-cy="zelo-c">
            <label class="form-check-label" for="zelo">
                Não se preocupa em manter a organização do setor, nem está atento a norma de conservação do material e patrimônio.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="zelo" value="d" data-cy="zelo-d">
            <label class="form-check-label" for="zelo">
                Desperdiça material de expediente, água, energia elétrica e demais recursos.
            </label>
        </div>
        <!-- RELAÇÕES INTERPESSOAIS -->
        <div class="row">
            <div class="col">
                <h4>10. RELAÇÕES INTERPESSOAIS</h4>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="interpessoalidade" value="a" data-cy="interpessoalidade-a">
            <label class="form-check-label" for=interpessoalidade>
                Possui habilidades no trato interpessoal, demonstrando cordialidade e respeito.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="interpessoalidade" value="b" data-cy="interpessoalidade-b">
            <label class="form-check-label" for=interpessoalidade>
                Evita enfrentar situações difíceis, nas quais tenha que agir diplomaticamente.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="interpessoalidade"  value="c" data-cy="interpessoalidade-c">
            <label class="form-check-label" for=interpessoalidade>
                Controla-se perante as situações difíceis, nas quais tenha que agir diplomaticamente.
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="interpessoalidade" value="d" data-cy="interpessoalidade-d">
            <label class="form-check-label" for=interpessoalidade>
                Cria situações difíceis, quando entra em contato com pessoas em geral.
            </label>
        </div>
        <br>
        <div class="d-grid gap-2">
            <button class="btn btn-light" type="button" v-on:click="validarCampos()" data-cy="confirmarQuestoes">Avançar</button>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    data(){
        return {
            resposta: [],
            erros: [],
            pontualidade: "",
            assiduidade: "",
            produtividade: "",
            eficiencia: "",
            adequacao: "",
            iniciativa: "",
            lideranca: "",
            planejamento: "",
            zelo: "",
            interpessoalidade: "",
            token: ""
        }
    },
    computed: {
    },
    mounted()
    {

    },
    methods: {
        validarForm()
        {
            this.erros = [];
            if(this.pontualidade === "") this.erros.push('<b>Pontualidade</b>');
            if(this.assiduidade === "") this.erros.push('<b>Assiduidade</b>');
            if(this.produtividade === "") this.erros.push('<b>Produtividade</b>');
            if(this.eficiencia === "") this.erros.push('<b>Eficiência</b>');
            if(this.adequacao === "") this.erros.push('<b>Adequação</b>');
            if(this.iniciativa === "") this.erros.push('<b>Iniciativa</b>');
            if(this.lideranca === "") this.erros.push('<b>Liderança</b>');
            if(this.planejamento === "") this.erros.push('<b>Planejamento</b>');
            if(this.zelo === "") this.erros.push('<b>Zelo</b>');
            if(this.interpessoalidade === "") this.erros.push('<b>Interpessoalidade</b>');

            if(this.erros.length === 0)
                return true;
            else
                return false;
        },  
        showAlertConfirm()
        {
            this.$swal({
              title: 'Você Confirma?',
              text: "Ao clicar, você confirma que você marcou as respostas mais adequadas.",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#198754',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Eu confirmo!',
              cancelButtonText: 'Cancelar'
            }).then((result) => { //isConfirmed true/false isDenied: true/false

                if (result.isConfirmed)
                {
                    if(this.validarForm())
                    {
                        let url = process.env.VUE_APP_HOST_API;
                        axios.post(url+'/avaliacao/servidor',{
                            matricula: this.$store.state.user.matricula,
                                q1: this.pontualidade,
                                q2: this.assiduidade,
                                q3: this.produtividade,
                                q4: this.eficiencia,
                                q5: this.adequacao,
                                q6: this.iniciativa,
                                q7: this.lideranca,
                                q8: this.planejamento,
                                q9: this.zelo,
                                q10: this.interpessoalidade
                        },{
                            headers: {
                                Accept: 'application/json',
                                Authorization: `Bearer ${this.$store.state.token}`
                            },
                            data: {
                                matricula: this.$store.state.user.matricula,
                                q1: this.pontualidade,
                                q2: this.assiduidade,
                                q3: this.produtividade,
                                q4: this.eficiencia,
                                q5: this.adequacao,
                                q6: this.iniciativa,
                                q7: this.lideranca,
                                q8: this.planejamento,
                                q9: this.zelo,
                                q10: this.interpessoalidade 
                            }
                        })
                        .then((response) => {
                            this.resposta = response.data.payload;
                            
                            this.$swal({
                                html: "<pre>Pronto!<br>Sua avaliação foi gravada com sucesso!<br> Sua nota foi: "+this.resposta.nota+"</pre>",
                                icon: 'success'});
                            this.enviar_email_copad();
                            this.$router.push("/");
                        }).catch(error => (
                            this.$swal({
                                html: "<pre>" + error + ".<br><br>Entre em contato com a equipe de TI.</pre>",
                                confirmButtonColor: 'red'
                            })
                        ));
                    }
                    else
                    {
                        const message = "<pre>Você precisa preencher todas as questões:<br><br>" + this.erros.join("<br>") + "</pre>";
                        this.$swal({
                            html: message
                        });
                    }
                }
            });
        },
        validarCampos()
        {
            if(true)
            {
                this.showAlertConfirm();
            }
        },
        utf8_to_b64(str)
        {
            return window.btoa(unescape(encodeURIComponent(str)));
        },
        enviar_email_copad()
        {
            let url = process.env.VUE_APP_HOST_API;
            this.$store.state.user.matricula
            axios.post(url+'/email-notificacao-servidor',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${localStorage.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");    
            }).catch(error => (
                console.log(error)
            )); 
        }
    }
}
</script>

<style>
    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
</style>