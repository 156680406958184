<template>
    <div class="container h-100" >
        <div class="row align-items-center justify-content-center h-100">
            <div class="col-10">
                <file-upload></file-upload>
            </div>
        </div>
    </div>

</template>

<script>
import  FileUpload from './FileUpload.vue';

export default {

    components: {
        FileUpload
    },
    mounted()
    {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>