<!-- App.vue -->
<template>
<nav class="bg-success">
  <div class="mx-auto px-2 sm:px-6 lg:px-12">
    <div class="relative flex h-16  justify-between">
      <div class="absolute inset-y-0 flex  sm:hidden"  >
        <!-- Mobile menu button-->
        <button type="button" v-on:click="hide()" class="relative inline-flex  justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
          <span class="absolute -inset-0.5"></span>
          <span class="sr-only">Open main menu</span>
          <!--
            Icon when menu is closed.

            Menu open: "hidden", Menu closed: "block"
          -->
          <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          <!--
            Icon when menu is open.

            Menu open: "block", Menu closed: "hidden"
          -->
          <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="flex flex-1  justify-center sm:items-stretch sm:justify-start">
        <div class="flex space-x-4">
          <a href="#" class=" hover:text-white text-gray-300 block rounded-md px-3 py-2 text-base  font-medium">Intranet</a>
        </div>
        <!-- Nav Items -->
        <div class="hidden sm:ml-6 sm:block direita">
          <div class="flex space-x-4 inset-y-0 right-0">
            <router-link to="/" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Home</router-link>
            <router-link to="/mural" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Mural Eletrônico</router-link>
            <router-link to="/chamados" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Chamados</router-link>
            <router-link to="/copad" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">COPAD</router-link>
            <router-link to="/sair" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" id="sair">Sair</router-link>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div class="sm:hidden" id="mobile-menu">
    <div class="space-y-1 px-2 pb-3 pt-2">
      <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
      <router-link to="/" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Home</router-link>
      <router-link to="/mural" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Mural Eletrônico</router-link>
      <router-link to="/chamados" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Chamados</router-link>
      <router-link to="/copad" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">COPAD</router-link>
      <router-link to="/sair" class="nav-link text-gray-300 hover:bg-green-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium" id="sair">Sair</router-link>
    </div>
  </div>
</nav>

  <main>
    <router-view />
  </main>
</template>

<script>

export default {

  components: {

  },
  data()
  {
      return {
        url : process.env.VUE_APP_HOST,
        botao: false
      }
  },
  mounted ()
  {
    if(this.$store.state.user == "")
        document.getElementById('sair').style.visibility = "visible"; 
    else
        this.$router.push("/login");    
  },
  methods: {
    hide(){
      var menu = document.getElementById('mobile-menu');
      if(menu.style.visibility == "hidden")
        menu.style.visibility = "visible"; 
      else
        menu.style.visibility = "hidden"
    }
  }
}
</script>

<style scoped>

.direita {
  margin-left: auto; 
  margin-right: 0;
}


</style>