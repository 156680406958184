<template>

    <div class="grid xl:grid-cols-10 lg::grid-cols-6 md:grid-cols-6 sm:grid-flow-col gap-4 place-items-center text-white overflow-auto"  :class="overlay">
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify">
            <p>Olá, {{$store.state.user.name}}.</p> 
            <p>Menu Admin</p>
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="alterarSenha()" data-cy="alterarSenha">Alterar Senha de Usuário</button>                
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="alterarMembroCopad()" data-cy="alterarMembroCopad">Administrar Membros da COPAD</button>                
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="alterarChefe()" data-cy="alterarChefe">Administrar Chefes</button>                
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="criarAdmin()" data-cy="criarAdmin">Criar Admin</button>                
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="criarUsuarioChefe()" data-cy="criarUsuarioChefe">Criar Usuário Chefe</button>                
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
            <button class="btn btn-light w-100" type="button" v-on:click="criarUsuario()" data-cy="criarUsuario">Criar Usuário</button>                
        </div>
    </div>
</template>
    
<script> 
    import axios from 'axios';

    export default {
    
        components: {
        },
        data()
        {
            return { 
                url: ''
            }
        },
        mounted()
        {
            if(this.$store.state.user == "")
                this.$router.push("/login");

            console.log(this.$store.state.user)

            this.url = process.env.VUE_APP_HOST_API;

        },
        methods: {
            alterarSenha()
            {
                this.$router.push("/admin/alterar/senha"); 
            },
            alterarMembroCopad()
            {
                this.$router.push("/admin/alterar/membro/copad");
            },
            alterarChefe()
            {
                this.$router.push("/admin/alterar/chefe");
            },
            criarAdmin()
            {
                this.$router.push("/admin/alterar/admin");
            },
            criarUsuarioChefe()
            {
                this.$router.push("/admin/criar/chefe");
            },
            criarUsuario()
            {
                this.$router.push("/admin/criar/usuario");
            }
        }
    }
</script>

<style scoped>
    
</style>