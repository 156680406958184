<template>
    <div class="flex items-center">
      <a :href=url class="flex items-center centro"><img :src="require('@/assets/brasao.png')" class="h-30"></a>
    </div>
  
      <form>
        <div class="grid lg:grid-rows-1 md:grid-rows-1 md:grid-cols-1 sm:grid-flow-col gap-4 place-items-center">
            <div class="white-box">
                <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input type="text" class="form-control" v-model="nome" data-cy="nome">
                </div>
                <div class="mb-3">
                    <label class="form-label">Matrícula</label>
                    <input type="text" class="form-control" v-model="matricula" data-cy="matricula">
                </div>
                <div class="mb-3">
                    <label class="form-label">Senha</label>
                    <input type="password" class="form-control" v-model="senha" data-cy="senha">
                </div>
                <div class="mb-3">
                    <label class="form-label">Repetir Senha</label>
                    <input type="password" class="form-control" v-model="repetirSenha" data-cy="repetirSenha">
                </div>
                <div class="mb-3 text-center">
                    <div class="form-check form-check-inline form-control-lg">
                        <input class="form-check-input" type="checkbox" id="checkboxAdmin" v-model="checkboxAdmin" value="admin">
                        <label class="form-check-label" for="checkboxAdmin">Admin</label>
                    </div>
                    <div class="form-check form-check-inline form-control-lg">
                        <input class="form-check-input" type="checkbox" id="checkboxChefe" v-model="checkboxChefe"  value="chefe">
                        <label class="form-check-label" for="checkboxChefe">Chefe</label>
                    </div>
                    <div class="form-check form-check-inline form-control-lg">
                        <input class="form-check-input" type="checkbox" id="checkboxCOPAD" v-model="checkboxCOPAD" value="copad">
                        <label class="form-check-label" for="checkboxCOPAD">COPAD</label>
                    </div>
                </div>
                <div class="content-center botao" >
                  <button type="submit" class="btn text-light bg-dark" @click.prevent="registrar" data-cy="submit">Registrar</button>
                </div>
            </div>
        </div>
      </form>
  </template>
  
  <script>
  
  import axios from 'axios';
  
  export default {
    components: {
  
    },
    data() {
        return {
            nome: "",
            matricula : "",
            senha: "",
            repetirSenha: "",
            checkboxChefe: false,
            checkboxAdmin: false,
            checkboxCOPAD: false,
            url : process.env.VUE_APP_HOST_API,
            erros: []
        }
    },
    mounted () {
      
    },
    methods: {
        registrar()
        {
            if(this.validarForm())
            {
                axios.post(this.url + "/registrar/usuario", {
                    nome: this.nome,
                    matricula: this.matricula,
                    senha: this.senha,
                    admin: this.checkboxAdmin,
                    copad: this.checkboxCOPAD,
                    chefe: this.checkboxChefe
                }).then((response) => {
                    const usuario = response.data.payload.user;
                    this.$swal({
                        title: 'Usuário Registrado com Sucesso.',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        console.log("Faz nada");
                    });
                }).catch((error) => {
                    this.$swal({
                        html: "<pre>" + error + ".<br><br>Erro ao Criar Usuário: Entre em contato com o Ivo.</pre>",
                        customClass: "swal-wide",
                        confirmButtonColor: 'red'
                    })
                });                
            }else
            {
                const message = "<pre>" + this.erros.join("<br>") + "</pre>";
                this.$swal({
                    html: message
                });
            }
        },
        validarForm()
        {
            this.erros = [];
            if(this.senha == "" || this.repetirSenha == "") this.erros.push('<b>Preencher Senha</b>');
            if(this.nome === "") this.erros.push('<b>Preencher nome</b>');
            if(this.matricula === "") this.erros.push('<b>Preencher Matrícula</b>');
            if(this.senha != this.repetirSenha) this.erros.push('<b>As senhas não conferem</b>');
            if(this.erros.length === 0)
                return true;
            else
                return false;
        }
    }
  }
  </script>
  
  <style scoped>
    .swal-wide{
        width: 40%;
    }
  
    .white-box{
      padding: 3rem;
      width:  50%;
    }
  
    label {
      font-weight: 700;
      color:white;
    }
  
    .botao {
      display: grid;
    }
  
    .centro {
      margin: 0px auto;
    }
  
    #brasao {
      visibility: hidden;
    }
  </style>
  