<template>
<div class="grid md:grid-rows-2 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
    <div><h2 class="text-center text-white">HABILITAR SERVIDORES PARA PROGRESSÃO VERTICAL</h2></div>
</div>
<div class="container h-100" >
        <div class="row align-items-center justify-content-center h-100 text-white text-center">
            <div class="col-10">
                <p>Olá, {{$store.state.user.name}}</p>
                <p>Chefe de Departamento</p>
                <br>
                <div class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="avaliarServidores()">Avaliar Servidores do Setor</button>
                    <button class="btn btn-light" type="button" v-on:click="relatorioServidoresAvaliados()">Relatório de Servidores Avaliados</button>
                </div>
                <br>
            </div>
        </div>
    </div>
</template>
    
<script>
    import 'moment/locale/pt-br';
    import axios from 'axios';
    
    export default {
    
        components: {
        },
        data()
        {
            return {
                apto: false,
                chefe: false,
                servidor: []
            }
        },
        mounted()
        {
            if(this.$store.state.user.chefe != 1)
                this.$router.push("/");     
        },
        methods: {
            relatorioServidoresAvaliados()
            {
                console.log("Relatório de Servidores Avaliados por Chefe");
                let url = process.env.VUE_APP_HOST_API;
    
                axios.post(url+'/relatorios/chefe/avaliados',{matricula: this.$store.state.user.matricula},{
                    headers: {
                        Accept: 'application/pdf',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    responseType: 'blob',
                    data: { matricula: this.$store.state.user.matricula }
                })
                .then((response) => {
                    console.log("Deu bom!");  
                    let blob = new Blob([response.data], {
                                type: 'application/pdf'
                            })
                            var pdf = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    a.href = pdf;
                    var filename = "Relatório de Servidores Avaliados por: " + this.$store.state.user.name;
                    a.download = filename;
                    a.click();
                }).catch(error => (
                    console.log(error)
                )); 
            },
            relatorioAvaliadosVerticalCOPAD()
            {
                console.log("Relatório de Avaliação");
                let url = process.env.VUE_APP_HOST_API;
    
                axios.post(url+'/relatorios/copad/avaliados-vertical-copad',{matricula: this.$store.state.user.matricula},{
                    headers: {
                        Accept: 'application/pdf',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    responseType: 'blob',
                    data: { matricula: this.$store.state.user.matricula }
                })
                .then((response) => {
                    console.log("Deu bom!");  
                    let blob = new Blob([response.data], {
                                type: 'application/pdf'
                            })
                    var pdf = window.URL.createObjectURL(blob)
                    window.open(pdf);
                }).catch(error => (
                    console.log(error)
                )); 
            },
            avaliarServidores()
            {
                this.$router.push("/avaliacao/chefe");
            }
        }
    }
</script>

<style scoped>
    p {
        font-size: x-large;
    }
</style>