<template>
    <div class="flex items-center">
      <a :href=url class="flex items-center centro"><img :src="require('@/assets/brasao.png')" class="h-30"></a>
    </div>
  
      <form>
        <div class="grid lg:grid-rows-1 md:grid-rows-1 md:grid-cols-1 sm:grid-flow-col gap-4 place-items-center">
            <div class="white-box">
                <div class="mb-3">
                    <label class="form-label">Nome</label>
                    <input type="text" class="form-control" v-model="nome" data-cy="nome">
                </div>
                <div class="mb-3">
                    <label class="form-label">Matrícula</label>
                    <input type="text" class="form-control" v-model="matricula" data-cy="matricula">
                </div>
                <div class="mb-3">
                    <label class="form-label">Senha</label>
                    <input type="password" class="form-control" v-model="senha" data-cy="senha">
                </div>
                <div class="mb-3">
                    <label class="form-label">Repetir Senha</label>
                    <input type="password" class="form-control" v-model="repetirSenha" data-cy="repetirSenha">
                </div>
                <div class="mb-3">
                    <label class="form-label">CPF</label>
                    <input type="text" class="form-control" v-model="cpf" data-cy="cpf">
                </div>
                <div class="mb-3">
                    <label class="form-label">Cargo</label>
                    <select class="form-select" aria-label="Default select example" v-model="cargo">
                        <option selected></option>
                        <option value="Presidente Comissão Licitação">Presidente Comissão Licitação</option>
                        <option value="Diretor de Comissões">Diretor de Comissões</option>
                        <option value="Chefe Serviços Gerais">Chefe Serviços Gerais</option>
                        <option value="Diretor de Recursos Humanos">Diretor de Recursos Humanos</option>
                        <option value="Gerente de Suporte Legislativo">Gerente de Suporte Legislativo</option>
                        <option value="Controlador Geral">Controlador Geral</option>
                        <option value="Procurador Geral">Procurador Geral</option>
                        <option value="Diretor de Comunicação">Diretor de Comunicação</option>
                        <option value="Diretor Administrativo">Diretor Administrativo</option>
                        <option value="Gerente de Segurança">Gerente de Segurança</option>
                        <option value="Superintendente Legislativo">Superintendente Legislativo</option>
                        <option value="Diretor de Finanças">Diretor de Finanças</option>
                        <option value="Presidente">Presidente</option>
                        <option value="Chefe de Departamento de Apoio Administrativo">Chefe de Departamento de Apoio Administrativo</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label class="form-label">Lotação</label>
                    <select class="form-select" aria-label="Default select example" v-model="lotacao">
                        <option selected></option>
                        <option value="Diretoria de Assistência Parlamentar às Comissões">Diretoria de Assistência Parlamentar às Comissões</option>
                        <option value="Departamento de Serviços Gerais">Departamento de Serviços Gerais</option>
                        <option value="Diretoria de Recursos Humanos">Diretoria de Recursos Humanos</option>
                        <option value="Diretoria de Suporte Legislativo">Diretoria de Suporte Legislativo</option>
                        <option value="Controle Interno">Controle Interno</option>
                        <option value="Procuradoria">Procuradoria</option>
                        <option value="Diretoria de Comunicação">Diretoria de Comunicação</option>
                        <option value="Diretoria Administrativa">Diretoria Administrativa</option>
                        <option value="Departamento de Segurança">Departamento de Segurança</option>
                        <option value="Superintendência Legislativa">Superintendência Legislativa</option>
                        <option value="Diretoria Financeira">Diretoria Financeira</option>
                        <option value="Presidência">Presidência</option>
                        <option value="Apoio Administrativo">Apoio Administrativo</option>
                    </select>
                </div>
                <div class="content-center botao" >
                  <button type="submit" class="btn text-light bg-dark" @click.prevent="registrar" data-cy="submit">Registrar</button>
                </div>
            </div>
        </div>
      </form>
  </template>
  
  <script>
  
  import axios from 'axios';
  
  export default {
    components: {
  
    },
    data() {
        return {
            nome: "",
            matricula : "",
            senha: "",
            repetirSenha: "",
            cpf: "",
            cargo: "",
            lotacao: "",
            url : process.env.VUE_APP_HOST_API,
            erros: []
        }
    },
    mounted () {
      
    },
    methods: {
        registrar()
        {
            if(this.validarForm())
            {
                axios.post(this.url + "/registrar/chefe", {
                    nome: this.nome,
                    matricula: this.matricula,
                    senha: this.senha,
                    cpf: this.cpf,
                    cargo: this.cargo,
                    lotacao: this.lotacao
                }).then((response) => {
                    const usuario = response.data.payload.user;
                    this.$swal({
                        title: 'Usuário (Chefe) Registrado com Sucesso.',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        console.log("Faz nada");
                    });
                }).catch((error) => {
                    this.$swal({
                        html: "<pre>" + error + ".<br><br>Erro ao Criar Usuário (Chefe): Entre em contato com o Ivo.</pre>",
                        customClass: "swal-wide",
                        confirmButtonColor: 'red'
                    })
                });                
            }else
            {
                const message = "<pre>" + this.erros.join("<br>") + "</pre>";
                this.$swal({
                    html: message
                });
            }
        },
        validarForm()
        {
            this.erros = [];
            if(this.senha == "" || this.repetirSenha == "") this.erros.push('<b>Preencher Senha</b>');
            if(this.nome === "") this.erros.push('<b>Preencher nome</b>');
            if(this.matricula === "") this.erros.push('<b>Preencher Matrícula</b>');
            if(this.senha != this.repetirSenha) this.erros.push('<b>As senhas não conferem</b>');
            if(this.cpf === "") this.erros.push('<b>Preencher CPF</b>');
            if(this.cargo === "") this.erros.push('<b>Selecionar Cargo</b>');
            if(this.lotacao === "") this.erros.push('<b>Selecionar Lotação</b>');
            if(this.erros.length === 0)
                return true;
            else
                return false;
        }
    }
  }
  </script>
  
  <style scoped>
    .swal-wide{
        width: 40%;
    }
  
    .white-box{
      padding: 3rem;
      width:  50%;
    }
  
    label {
      font-weight: 700;
      color:white;
    }
  
    .botao {
      display: grid;
    }
  
    .centro {
      margin: 0px auto;
    }
  
    #brasao {
      visibility: hidden;
    }
  </style>
  