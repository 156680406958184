import { createApp } from 'vue'
import App from '../src/App'
import router from './router/router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.all.js'

import './index.css'

createApp(App).use(store).use(router).use(VueSweetalert2).mount('#app')
