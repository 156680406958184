<template>
    <div class="grid md:grid-rows-2 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white">HABILITAR SERVIDORES PARA PROGRESSÃO VERTICAL</h2></div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servidor</th>
                      <th scope="col">Aptidão</th>
                    </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(servidor, index) in servidores" :key="servidor.matricula">
                      <th scope="row">{{index+1}}</th>
                      <td v-on:click="habilitarServidor(servidor.matricula)"><a href="#">{{servidor.nome}}</a></td>
                      <td v-if="servidor.apto === aptidao.NAOESTAVEL"><a href="#">NÃO ESTÁVEL</a></td>
                      <td v-if="servidor.apto === aptidao.HORIZONTAL"><a href="#">APTO HORIZONTAL</a></td>
                      <td v-if="servidor.apto === aptidao.VERTICAL"><a href="#">APTO VERTICAL</a></td>
                      <td v-if="servidor.apto === aptidao.FIMDECARREIRA"><a href="#">FIM DE CARREIRA</a></td>
                      <td v-if="servidor.apto === aptidao.SEMAPTIDAO"><a href="#">SEM APTIDAO</a></td>
                    </tr>
                  </tbody>
              </table>       
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';


export default {

    components: {

    },
    data()
    {
        return {
            servidores: [],
            aptidao: {
                VERTICAL: 1,
                HORIZONTAL: 2,
                FIMDECARREIRA: 3,
                SEMAPTIDAO: 4,
                NAOESTAVEL: 5
            },
            apto: 0
        }
    },
    mounted()
    {
        if(this.$store.state.user.copad != 1)
           this.$router.push("/");
           
        this.getServidores();    
    },
    methods: {
        getServidores()
        {                
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/copad/aptidao/servidores',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {servidor: this.$store.state.user.matricula}
            })
            .then((response) => {
                this.servidores = response.data.payload;
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
            });
            
        },
        async habilitarServidor(matricula)
        {
            let { value: aptidao} = await this.$swal.fire({
                title: 'Escolha o tipo de Apto',
                input: 'select',
                inputOptions: {
                    0: 'Nâo Estável',
                    1: 'Apto Vertical',
                    2: 'Apto Horizontal',
                    3: 'Fim de Carreira',
                    4: 'Estável sem Aptidão' //,5: 'Horizontal e Vertical'
                },
                inputPlaceholder: 'Selecionar',
                showCancelButton: true
            });
            
            if(aptidao !== undefined)
            {
                this.apto = parseInt(aptidao);
                let url = process.env.VUE_APP_HOST_API;
                axios.post(url+'/copad/habilitar/servidor',{matricula: matricula, aptidao: aptidao},{
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    data: {matricula: matricula, aptidao: aptidao}
                })
                .then((response) => {
                    this.apto = response.data.payload.apto;

                    this.$swal("Você alterou o servidor para: " + this.msgApto(this.apto));
                    this.getServidores();
                }).catch((error) => {
                    alert(error)
                    console.log("Respostas não carregadas!!!");
                });
            }

            
        },
        msgApto(valor)
        {
            if(valor === this.aptidao.NAOESTAVEL)
                return "NÃO ESTÁVEL";
            else if(valor  === this.aptidao.HORIZONTAL)
                return "APTO HORIZONTAL";
            else if(valor  === this.aptidao.VERTICAL)
                return "APTO VERTICAL";
            else if(valor  === this.aptidao.FIMDECARREIRA)
                return "FIM DE CARREIRA";
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000;
    font-size: x-large;
}

th {
    color: white;
}

table, thead{
    border: 1px solid white;
}

</style>
