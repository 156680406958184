<template>
    <div class="grid xl:col-start-3 xl:col-span-end-7 md:grid-rows-3 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white"> </h2></div>
    </div>
    <div class="grid xl:grid-cols-10 lg::grid-cols-6 md:grid-cols-6 sm:grid-flow-col gap-4 place-items-center text-white" :class="overlay">
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-center">
                <p>Olá, {{$store.state.user.name}}.</p> 
                <p>Bem-vindo à página da COPAD.</p>
        </div>
        <div class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
                <button class="btn btn-light w-100" type="button" v-on:click="paginaServidor()" data-cy="paginaServidor">Eu sou Servidor Estável</button>                
        </div>
        <div v-if="$store.state.user.copad === 1" class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
                    <button class="btn btn-light w-100" type="button" v-on:click="paginaMembrosCopad()" data-cy="paginaMembrosCopad">Eu sou membro da COPAD</button>
        </div>
        <div  v-if="$store.state.user.chefe === 1" class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
                    <button class="btn btn-light w-100" type="button" v-on:click="paginaChefe()" data-cy="paginaChefe">Eu sou Chefe de Departamento</button>
        </div>
        <div  v-if="$store.state.user.admin === 1" class="xl:col-start-4 xl:col-span-end-7 lg:col-start-1 lg:col-span-end-7 md:col-start-2 md:col-span-4 text-justify w-100">
                    <button class="btn btn-light w-100" type="button" v-on:click="paginaAdmin()" data-cy="paginaAdmin">Eu sou Administrador do Sistema</button>
        </div>
    </div>
</template>
    
<script>

export default {

    components: {
    },
    data()
    {
        return {
            servidor: []
        }
    },
    mounted()
    {
        if(this.$store.state.user == "")
            this.$router.push("/login");
    },
    methods: {
        paginaMembrosCopad()
        {
            this.$router.push("/copad/membro");
        },
        paginaServidor()
        {
            this.$router.push("/copad/servidor");
        },
        paginaChefe()
        {
            this.$router.push("/copad/chefe");
        },
        paginaAdmin()
        {
            this.$router.push("/admin");
        }
    }
}

</script>

<style scoped>

    p, li {
        font-size: x-large;
    }


</style>