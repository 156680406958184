<template>
    <div >
        <h1 class="text-white text-center">Avaliação de Desempenho</h1>
        <AvaliacaoServidorForm></AvaliacaoServidorForm>
    </div>

</template>

<script>
import AvaliacaoServidorForm from './AvaliacaoServidorForm.vue';

export default {

    components: {
        AvaliacaoServidorForm
    },
    data() {
        return { }
    },
    mounted()
    {
       
    },
    methods:
    {
    }
}
</script>

<style scoped>
 .logo {
    visibility: hidden;
 }
</style>