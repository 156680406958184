<template>
    <div class="container h-100" >
            <div class="row align-items-center justify-content-center h-100 text-white">
                <div class="col-10" style="padding: 3rem; border: .5rem solid white; border-radius: .2rem;">
                    <p>Você, funcionário avaliado nos últimos 3 anos, de acordo com a Resolução nº 225/2023,
                        pode solicitar a Progressão Vertical.
                    </p>
                    <p>Todo processo é 100% online, seguro, discreto, sigiloso.</p><br>
                    <div class="d-grid gap-2">
                        <button class="btn btn-light" type="button" v-on:click="validarCampos()">Avançar</button>
                    </div>
                </div>
            </div>
        </div>
    
    </template>
    
<script>
    export default
    {
    
        components: {
        },
        mounted()
        {
            if(this.$store.state.user == "")
                this.$router.push("/login");
        },
        methods: {
            validarCampos()
            {
                this.$router.push("/progressao/upload");
            },
            solicitarProgressao()
            {
                this.$router.push("/progressao");
            }
        }
    }
</script>

<style scoped>

</style>