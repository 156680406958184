<template>
<div class="text-center text-white">
    <h1>Arquivos Enviados à COPAD</h1>
    <h2>Matrícula: {{this.$route.params.servidor}}</h2>
</div>
<div class="container">
    <div class="row">
        <div class="col-12">
            <table class="table table-bordered table-hover">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Arquivo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file,index) in files" :key="file.nome">
                    <th scope="row">{{index+1}}</th>
                    <td v-on:click="openFile(file)"><a href="#" class="text-dark"><span class="red"><filePDF class="large" />&nbsp;</span>{{file}}</a></td>
                    </tr>
            
                </tbody>
            </table>  
            <br>
            <h3 class="text-center text-white">Finalizar Avaliação?</h3>

            <form @submit.prevent>
                <select class="form-select" aria-label="O servidor está apto?" v-model="aptidao"> 
                    <option value="1">Apto</option>
                    <option value="0">Inapto</option>
                </select>
                <br>
                <div class="col-12 text-center">
                    <button type="submit" class="btn btn-light text-dark" @click="avaliacaoCopad"><b>Confirmar</b></button>
                </div>
            </form>
        </div>
    </div>
</div>
</template>
  
<script>
    import axios from 'axios';
    import filePDF from '../../Icons/filePDF.vue';

    export default {
  
      components: {
          filePDF
      },
      data() {
          return {
              servidor : [],
              files: [],
              url: "",
              parecer: "",
              justificativa: "",
              aptidao: ""
          }
      },
      mounted()
      {
          this.servidor = this.$route.params.servidor;
  
          this.url = process.env.VUE_APP_HOST_API;
          axios.post(this.url+'/dados/vertical',{servidor: this.$route.params.servidor},{
              headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${this.$store.state.token}`
              },
              data: {
                  servidor: this.$route.params.servidor
              }
          })
          .then((response) => {
              this.files = response.data.payload;
              console.log(this.files);
          }).catch(error => (
              console.log(error)
          ));
      },
      methods:
      {
        avaliacaoCopad()
        {
            if(this.aptidao !== "")
            {
                this.url = process.env.VUE_APP_HOST_API;

                axios.post(this.url+'/copad/servidor/finalizar',{matricula: this.$route.params.servidor, aptidao: this.aptidao},{
                    headers: {
                        Accept: 'application/pdf',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    data: {
                        matricula: this.$route.params.servidor,
                        aptidao: this.aptidao
                    }
                })
                .then((response) => {
                    console.log("Avaliado!!!");  
                    this.$swal({
                                html: "A avaliação do servidor de matrícula: <b>" + this.$route.params.servidor + "</b> foi finalizada!",
                                confirmButtonColor: 'green'
                            })
                }).catch(error => (
                    console.log(error)
                ));
            }
        },
        openFile(path)
        {
            this.url = process.env.VUE_APP_HOST_API;
            console.log(path);
            axios.post(this.url+'/show/pdf',{servidor: this.$route.params.servidor, file: path},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: {
                    servidor: this.$route.params.servidor,
                    file: path
                }
            })
            .then((response) => {
                console.log(response.data);  
                let blob = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                        var pdf = window.URL.createObjectURL(blob);

                var a = document.createElement("a");
                a.href = pdf;
                var filename = path;
                a.download = filename;
                a.click();
            }).catch(error => (
                console.log(error)
            ));
        }
    }
}
  </script>
  
  <style scoped>
  a {
      text-decoration: none;
      color: #fff;
      font-size: x-large;
  }

  .large {
    transform: scale(1.5);
  }
  
  .red{
      color: red;
      font-size: x-large;
  }
  
  th {
      color: white;
  }
  
  table, thead{
      border: 1px solid white;
  }
  
  td {
      text-align: left;
  }
  
  </style>