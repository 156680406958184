<template>
    <div class="text-center text-white">
        <h1>AVALIAÇÃO DO CHEFE IMEDIATO</h1>
    </div>
    <div class="container text-white">
        <div class="row">
            <div class="col-12">
                    <div class="row">
                        <div class="col-6"><p>Matrícula: <span class="display">{{$store.state.user.matricula}}</span></p></div>
                        <div class="col-6"><p>Nome: <span class="display">{{$store.state.user.name}}</span></p></div>
                    </div>
                    <div class="row">
                        <div class="col-6"><p>Cargo em Comissão: <span class="display">{{$store.state.user.cargo}}</span></p></div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <h2 class="text-center">RESPOSTAS DO SERVIDOR: {{servidor.nome}}</h2> 
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6"><p>Matrícula: <span class="display">{{servidor.matricula}}</span></p></div>
                        <div class="col-6"><p>Nome: <span class="display">{{servidor.nome}}</span></p></div>
                    </div>
                    <RespostasServidorForm servidor={{servidor.matricula}}></RespostasServidorForm>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col"><p><b>Parecer:</b></p>
                <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="parecer" value="apto">
                    <label class="form-check-label" for="parecer" style="float: left;">Apto</label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" v-model="parecer" value="inapto">
                    <label class="form-check-label" for="parecer" style="float: left;">Inapto</label>
                </div>
            </div>
            <br>
            <div class="mb-3" v-if="parecer == 'inapto'">
                <label for="justificativa" class="form-label" style="font-size: x-large;">Justificativa</label>
                <textarea class="form-control" rows="10" v-model="justificativa"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-grid gap-2">
                    <button class="btn btn-light" type="button" v-on:click="avaliar()">Avaliar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import RespostasServidorForm from './RespostasServidorForm.vue';

export default {

    components: {
        RespostasServidorForm
    },
        data()
    {
        return {
            servidor : [],
            parecer: "",
            justificativa: ""
        }
    },
    mounted()
    {
        if(this.$store.state.user.chefe != 1)
            this.$router.push("/");

        this.getServidores();    
    },
    methods: {
        getServidores()
        {            
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/servidor',{servidor: this.$route.params.servidor},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {
                    servidor: this.$route.params.servidor
                }
            })
            .then((response) => {
                this.servidor = response.data.payload;
                console.log(this.servidor);
            }).catch(error => (
                console.log(error)
            ));
        },
        validarForm()
        {
            this.erros = [];
            if(this.parecer === "") this.erros.push('<b>Parecer</b>');
            if(this.justificativa === "" && this.parecer === 'inapto') this.erros.push('<b>Você precisa preencher uma justificatia!</b>');
            if(this.erros.length === 0)
                return true;
            else
                return false;
        },
        enviar_email_copad()
        {
            let url = process.env.VUE_APP_HOST_API;

            axios.post(url+'/email-notificacao-chefe',{matricula: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                responseType: 'blob',
                data: { matricula: this.$store.state.user.matricula }
            })
            .then((response) => {
                console.log("Deu bom!");   
                console.log(response);
            }).catch(error => (
                console.log(error)
            )); 
        },
        avaliar()
        {
            this.$swal({
              title: 'Você Confirma?',
              text: "Ao clicar, você confirma que você marcou as respostas mais adequadas.",
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#198754',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Eu confirmo!',
              cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed)
                {
                    if(this.validarForm())
                    {
                        var estaApto = 0;
                        this.parecer == "apto" ? estaApto = 1 : estaApto = 0;
                        let url = process.env.VUE_APP_HOST_API;

                        axios.post(url+'/avaliacao/chefe/servidor',{servidor: this.servidor.matricula,
                                parecer: estaApto,
                                justificativa: this.justificativa,
                                chefe: this.$store.state.user.matricula},{
                            headers: {
                                Accept: 'application/json',
                                Authorization: `Bearer ${this.$store.state.token}`
                            },
                            data: {
                                
                            }
                        })
                        .then((response) => {
                            this.resposta = response.data;
                            this.enviar_email_copad();
                            this.$swal({
                            html: "<pre>Pronto!<br>Seu parecer foi salvo com sucesso!<br> Servidor: "+this.servidor.matricula+", foi avaliado!</pre>",
                            icon: 'success'});
                            this.$router.push("/");
                        }).catch(error => (
                            this.$swal({
                                html: "<pre>" + error + ".<br><br>Entre em contato com a equipe de TI.</pre>",
                                confirmButtonColor: 'red'
                            })
                        ));
                    }else
                    {
                        const message = "<pre>Você precisa preencher todas as questões:<br><br>" + this.erros.join("<br>") + "</pre>";
                        this.$swal({
                            html: message
                        });
                    }
                }
            });
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #fff;
    font-size: x-large;
}

p{
    text-align: left;
}

.display {
    font-size: larger;
    text-shadow: 2px 2px 4px #000000;
}

</style>
