<template>
    <div class="grid md:grid-rows-2 grid-flow-col gap-4 place-items-center sm:grid-flow-col">
        <div><h2 class="text-center text-white">HABILITAR SERVIDOR COMO CHEFE</h2></div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Servidor</th>
                      <th scope="col">Admin</th>
                    </tr>
                  </thead>
                  <tbody class="text-dark">
                    <tr v-for="(servidor, index) in servidores" :key="servidor.matricula">
                      <th scope="row">{{index+1}}</th>
                      <td v-on:click="habilitarServidor(servidor.matricula)"><a href="#">{{servidor.name}}</a></td>
                      <td v-if="servidor.admin === admin.ADMIN"><a href="#"><b>Admin</b></a></td>
                      <td v-if="servidor.admin === admin.USUARIO"><a href="#">Usuário</a></td>
                    </tr>
                  </tbody>
              </table>       
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';


export default {

    components: {

    },
    data()
    {
        return {
            servidores: [],
            admin: {
                ADMIN: 1,
                USUARIO: 0
            }
          
        }
    },
    mounted()
    {
        if(this.$store.state.user.admin != 1)
           this.$router.push("/");
           
        this.getServidores();    
    },
    methods: {
        getServidores()
        {                
            let url = process.env.VUE_APP_HOST_API;
            axios.post(url+'/users',{servidor: this.$store.state.user.matricula},{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${this.$store.state.token}`
                },
                data: {servidor: this.$store.state.user.matricula}
            })
            .then((response) => {
                this.servidores = response.data.payload;
            }).catch((error) => {
                console.log("Respostas não carregadas!!!");
            });
            
        },
        async habilitarServidor(matricula)
        {
            let { value: admin} = await this.$swal.fire({
                title: 'É ADMIN?',
                input: 'select',
                inputOptions: {
                    0: 'Não',
                    1: 'Sim'
                },
                inputPlaceholder: 'Selecionar',
                showCancelButton: true
            });
            
            if(admin !== undefined)
            {
                admin = parseInt(admin);
                let url = process.env.VUE_APP_HOST_API;
                axios.post(url+'/copad/habilitar/admin',{matricula: matricula, admin: admin},{
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${this.$store.state.token}`
                    },
                    data: {matricula: matricula, admin: admin}
                })
                .then((response) => {
                    admin = response.data.payload.admin;

                    this.$swal("O servidor agora: " + this.msgApto(admin));
                    this.getServidores();
                }).catch((error) => {
                    alert(error)
                    console.log("Respostas não carregadas!!!");
                });
            }

            
        },
        msgApto(valor)
        {
            if(valor === this.admin.ADMIN)
                return "É ADMIN";
            else if(valor  === this.admin.USUARIO)
                return "NÃO É ADMIN";
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #000;
    font-size: x-large;
}

th {
    color: white;
}

table, thead{
    border: 1px solid white;
}

</style>
