<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card card-default">
                    <div class="card-header">Faça o Carregamento (Upload) de seus certificados</div>
                    <div class="card-body">
                        <input type="hidden" v-model="matricula" name="matricula">
                       <div class="row">
                          <div class="col-md-12" v-if="image">
                              <img :src="image" class="img-responsive" height="70" width="90">
                           </div>
                          <div class="col-md-12">
                              <input type="file" ref="pdfs" @change="selectedFile" class="form-control" accept=".pdf" multiple>
                          </div>
                       </div>
                       <div class="row">
                          <div class="col-md-12" style="padding-top: 2rem;">
                            <ul>
                                <li v-for="arquivo in arquivos" :key="arquivo.name">
                                    {{ arquivo }}
                                </li>
                            </ul>
                          </div>
                       </div>
                      <div class="row">
                        <div class="col-md-12" style="padding-top: 2rem;">
                             <button class="btn btn-success btn-block" @click="uploadImage">Carregar</button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

    export default {
        data(){
            return {
                image: '',
                arquivos: [],
                matricula: ''
            }
        },
        mounted() {
            this.matricula = this.$store.state.user.matricula;
        },
        methods: 
        {
            selectedFile() 
            {
                console.log('selected a file');
                this.arquivos = [];
                console.log(this.$refs.pdfs.files[0]);
                
                let file = this.$refs.pdfs.files[0];
                let files = this.$refs.pdfs.files;
                var validated = true;
                var i = 0;

                for(i=0; i<files.length; i++)
                {
                    this.arquivos.push(files[i].name);
                    if(!files[i] || files[i].type !== 'application/pdf')
                    {
                        console.log("Não é PDF: " + files[i].type);
                        validated = false;
                    }else
                    {
                        console.log("É PDF: " + files[i].type);
                    }
                }

                console.log("FIM");
            }
            ,
            uploadImage()
            {
                const form = new FormData();
                var fileList = this.$refs.pdfs.files;

                for(let i=0; i<fileList.length;i++)
                {
                    form.append("certificados[]", this.$refs.pdfs.files[i]);
                }

                form.append('matricula',this.matricula);

                let httpBase = process.env.VUE_APP_HOST_API;

                axios({
                    method: 'POST',
                    url: httpBase + '/pdf',
                    data: form,
                    headers: { 
                        "Content-Type": "multipart/form-data; charset=utf-8; boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL",
                        Authorization: `Bearer ${this.$store.state.token}`}  
                }).then((response) => {
                    const message = "<pre><br><br></pre>";
                    this.$swal("Você carregou seus arquivos. Agora aguarde a avalição da COPAD.");
                }).catch(function (response) {
                    console.log(response);
                });
            }
        }
    }
</script>

<style scoped>
.card-header, li {
    color: black;
}

ul li {
    text-align: left;
}
</style>